<template>
  <span @click='showModal'>{{ price }}</span>
  <div v-if='showMainModal' id='sl-history-log-modal-container'>
    <br><br><br>
    <div class='sl-history-log-modal-content' style='max-width: 600px; height: 500px'>
      <CloseIcon class='figma-icon' @click='closeModal' id='sl-history-log-close' style='float:right; margin-bottom: 10px'/>
      <table class='sl-simple-table-1 sl-border-b-2px'>
        <thead>
          <tr>
            <th class='sl-w-100px'></th>
            <th>Pricing Factor</th>
            <th>Fee</th>
            <th class='sl-w-100px'></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in pricing_factors">
            <th></th>
            <td>{{ row.name }}</td>
            <td>{{ numberToCurrency(pricing_data[row.column]) }}</td>
            <td></td>
          </tr>
          <tr>
            <th></th>
            <td>Conversion Fee</td>
            <td>{{ numberToCurrency(conversion_fee) }}</td>
            <td></td>
          </tr>
          <tr class='sl-border-t-2px-medium-charcoal'>
            <td></td>
            <td class='sl-bold-text'>Total Fee</td>
            <td class='sl-bold-text'>{{ numberToCurrency(pricing_data.total_fee) }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <br>
      <span class='sl-mr-5px' style="font-size: 20px; color: #F04C25">*</span>
      <span>Pricing details are only visible to Staff users.</span>
    </div>
  </div>
</template>

<script>
import CloseIcon from '../../../../assets/images/icons/Icon-Close_Charcoal-Dark-100_24.svg';

export default {
  props: {
    service_estimates_and_selections_data: Object,
    price: Number,
    pricing_data: Object,
    conversion_fee: Number,
  },

  components: {
    CloseIcon,
  },

  data() {
    return {
      showMainModal: false,
      pricing_factors: [
        { name: 'Base Fee', column: 'base_fee' },
        { name: 'Travel Difficulty Fee', column: 'travel_difficulty_fee' },
        { name: 'Limit of Liability Fee', column: 'limit_of_liability_fee' },
        { name: 'Turnaround Time Fee', column: 'tat_fee' },
        { name: 'Units Fee', column: 'units_fee' },
        { name: 'Size Fee', column: 'size_fee' },
        { name: 'Time Period Fee', column: 'time_period_fee' },
      ],
    };
  },

  methods: {
    showModal() {
      this.showMainModal = true;
    },
    closeModal() {
      this.showMainModal = false;
    },
    numberToCurrency(number) {
      if (!number) return '$0';
      return `$${Intl.NumberFormat().format(number)}`;
    },
  },
};
</script>
<style scoped>
  table th,
  table td {
    text-align: left;
  }
</style>
